
import { ICSSClientBudget } from 'app/api/icss/types';
import { ReportsType } from 'app/api/reports/types';

const useCreateICSSColumns = (sheetData: ICSSClientBudget, report: ReportsType) => {
    const createdColumns = Object.keys(sheetData || {}).map((name) => {
        const reportReadonly = (report.readOnly || []);
        const reportHidden = (report.hidden || []).concat(['IS_SAVED']);

        return ({
            field: name,
            width: report.columnWidth ? report.columnWidth[name] : undefined,
            hide: reportHidden.includes(name),
            editable: !reportReadonly.includes(name),
            headerName: report.columnNames ? report.columnNames[name] : name,
            filter: report.filter ? report.filter[name] : 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
                closeOnApply: true
            },
            cellEditor: report.cellEditor ? report.cellEditor[name] : 'agTextCellEditor',
            cellEditorPopup: report.cellEditor && report.cellEditor[name] === 'agLargeTextCellEditor',
            cellEditorParams: report.cellEditorParams && report.cellEditorParams[name]
        });
    });

    const index = createdColumns.findIndex(column => column.field === 'CLIENT_NAME');

    if (index !== -1) {
        const [clientNameColumn] = createdColumns.splice(index, 1); // Remove the "CLIENT_NAME" column

        createdColumns.unshift(clientNameColumn); // Add it to the beginning
    }

    return (createdColumns);
};

export default useCreateICSSColumns;
