import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import AppButton from 'app/components/Button/AppButton';
import useToast from 'app/hooks/useToast';
import useConfigStore from 'app/store/userConfig';
import useRightDrawerStore from 'app/store/rightDrawer';
import Typography from 'app/components/Typography';
import AppSelect from 'app/components/Select';
import AppDatePicker from 'app/components/AppDatePicker';
import { ReportsType } from 'app/api/reports/types';
import { AirportsScheduleData } from 'app/api/airports/types';
import { saveAirportsScheduleData, useGetAirportsScheduleData, useGetAirportsScheduleDataOptions } from 'app/api/airports/airports';
import { MONTHS, ORIENTATION } from 'app/utils/constants';
import { findChangedObjects, updateRightDrawerData } from 'app/utils/helpers';
import AgGrid from './AgGrid';
import SpreadsheetExport from './SpreadsheetExport';
import useCreateAirportGridColumns from './hooks/useCreateAirportGridColumns';
import useAddRow from './hooks/useAddRow';
import useSpreadsheet from '../../hooks/useSpreadsheet';
import useDeleteRows from './hooks/useDeleteRows';
import useDuplicateScenario from './hooks/useDuplicateScenario';
import useDuplicateRows from './hooks/useDuplicateRows';
import useUpdateRows from './hooks/useUpdateRows';

import './spreadsheet-grid-airports-schedule.scss';

type Props = {
    report: ReportsType;
    className?: string;
}

const SpreadsheetGridAirportsSchedule = ({ report, className }: Props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [dateRangeFilter, setDateRangeFilter] = useState<{ startDate: string | null; endDate: string | null }>({
        startDate: null,
        endDate: null
    });
    const [airport, setAirport] = useState('ABM');
    const [monthFilter, setMonthFilter] = useState<string | null>();
    const [freshRecords, setFreshRecords] = useState<AirportsScheduleData[]>();

    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { setNotification } = useToast();
    const { navbarOrientation } = useConfigStore();

    const { data: dataOptions, refetch: refetchDataOptions, isSuccess: isOptionsSuccess } = useGetAirportsScheduleDataOptions(
        {
            airport
        },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false
        });

    const [currentVersion, setCurrentVersion] = useState<{ ID: string, NAME: string }>({ ID: '', NAME: '' });

    const { data: sheetDataResponse, isLoading: isSpreadsheetDataLoading, refetch } = useGetAirportsScheduleData(
        {
            version: currentVersion.ID || (dataOptions?.data.data.VERSION_INFO[0].ID || ''),
            airport,
            startDate: dateRangeFilter.startDate || '',
            endDate: dateRangeFilter.endDate || '',
            month: monthFilter ? (MONTHS.indexOf(monthFilter) + 1).toString() : ''
        },
        {
            enabled: isOptionsSuccess,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            keepPreviousData: true,
            onSuccess: (data) => {
                if (!currentVersion.ID) {
                    setCurrentVersion(dataOptions?.data.data.VERSION_INFO[0] || { ID: '', NAME: '' });
                }

                const updatedLoadFactorSheet = data.data.data.map((data: any) => ({ ...data, LOAD_FACTOR: parseFloat((data.LOAD_FACTOR * 100).toFixed(2)) }));

                setFreshRecords(updatedLoadFactorSheet);
            }
        });

    const { gridApi, pageSize, isSheetUpdated, setIsSheetUpdated, handleGridReady, onPageSizeChanged, onCellValueChanged } = useSpreadsheet(isSpreadsheetDataLoading);

    const sheetData: AirportsScheduleData[] = useMemo(() => {
        const parsedSheetData: AirportsScheduleData[] = sheetDataResponse ? JSON.parse(JSON.stringify(sheetDataResponse.data.data)) : [];

        const updatedLoadFactorSheet = parsedSheetData.map((data) => ({ ...data, LOAD_FACTOR: parseFloat((data.LOAD_FACTOR * 100).toFixed(2)) }));

        return updatedLoadFactorSheet;
    }, [sheetDataResponse]);

    const createdColumns = useCreateAirportGridColumns(sheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [sheetData, report]);
    const dataOptionsMemo = useMemo(() => dataOptions ? dataOptions.data.data : undefined, [dataOptions]);

    const handleRefetchData = async () => {
        await refetch();
        await refetchDataOptions();
    };

    const isVersionLocked = !!(sheetData.length > 0 && sheetData[0].VERSION_IS_LOCKED);

    const { handleDeleteModal } = useDeleteRows(handleRefetchData, report.scheduleName || '');
    const { handleAddRow, isLoading: isLoadingAddRow } = useAddRow(refetch, gridApi, report.scheduleName || '');
    const { handleDuplicateScenario } = useDuplicateScenario(sheetData || [], airport, currentVersion.ID, handleRefetchData, isVersionLocked, dataOptions?.data.data.VERSION_INFO || [], report.scheduleName || '');
    const { handleDuplicateRows } = useDuplicateRows(airport, handleRefetchData, isVersionLocked, dataOptions?.data.data.VERSION_INFO || [], report.scheduleName || '');
    const { handleUpdateRows } = useUpdateRows(dataOptionsMemo ? dataOptionsMemo.AIRCRAFT.map(a => a.DISPLAY_CODE) : [], airport, currentVersion.ID, handleRefetchData, report.scheduleName || '');

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <SpreadsheetExport sheetData={sheetData} isSheetUpdated={isSheetUpdated} />
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, sheetData]);

    const handleSaveSpreadsheet = async () => {
        setIsSaving(true);

        const updatedValues: AirportsScheduleData[] = [];

        if (gridApi) {
            gridApi.forEachNode((node) => {
                const updatedValue = node.data;

                updatedValues.push(updatedValue);
            });
        }

        try {
            const changedObjects = findChangedObjects<AirportsScheduleData>(freshRecords || [], updatedValues);

            await saveAirportsScheduleData(changedObjects, { scheduleName: report.scheduleName || '' });
            refetch();
            setNotification('Sheet saved', 'success');

            setIsSheetUpdated(false);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setNotification(error.response.data.message, 'error');
            } else {
                setNotification('Failed to update spreadsheet', 'error');
            }
        } finally {
            setIsSaving(false);
        }
    };

    const handleAddRowGrid = async () => await handleAddRow(currentVersion.ID, airport);

    const onChangeDate = (type: 'startDate' | 'endDate', date: Date | null) => {
        const formattedDate = date ? moment(date).format('DD/MM/yyyy') : '';

        setDateRangeFilter({ ...dateRangeFilter, [type]: formattedDate });
    };

    const onSelectionChanged = useCallback(() => {
        if (gridApi) {
            const selectedRows = gridApi.getSelectedRows().length;

            document.querySelector('#selectedRows')!.innerHTML = 'Rows Selected: ' + selectedRows.toString();
        }
    }, [gridApi]);

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
            </div>
            <div className={`spreadsheet-grid-airports-schedule card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex gap-3' >
                        <AppSelect
                            menuPortalTarget={document.body}
                            // @ts-ignore
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            value={{ label: currentVersion.NAME, value: currentVersion.ID }}
                            onChange={(e) => setCurrentVersion(e?.value && e.label ? { ID: e.value, NAME: e.label } : { ID: '', NAME: '' })}
                            className='w-175px'
                            options={dataOptions?.data.data.VERSION_INFO.map((version) => ({ label: version.NAME, value: version.ID }))} />
                        <AppSelect value={{ label: airport, value: airport }}
                            menuPortalTarget={document.body}
                            // @ts-ignore
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            onChange={(e) => {
                                setCurrentVersion({ ID: '', NAME: '' });
                                setAirport(e!.value);
                            }}
                            className='w-150px'
                            options={dataOptions?.data.data.AIRPORTS.map((airport) => ({ label: airport, value: airport }))} />
                        <div className='d-flex align-items-center justify-content-center gap-2'>
                            <AppDatePicker
                                dateFormat='dd/MM/yyyy'
                                showCustomHeader={true}
                                showIcon={true}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                icon='fa fa-calendar'
                                className='w-150px'
                                isClearable={true}
                                placeholderText='Select Start Date'
                                onChange={(date) => onChangeDate('startDate', date)}
                                selected={dateRangeFilter.startDate ? moment(dateRangeFilter.startDate, 'DD/MM/yyyy').toDate() : null} />
                            <span>&mdash;</span>
                            <AppDatePicker
                                dateFormat='dd/MM/yyyy'
                                showCustomHeader={true}
                                showIcon={true}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                icon='fa fa-calendar'
                                className='w-150px'
                                isClearable={true}
                                placeholderText='Select End Date'
                                onChange={(date) => onChangeDate('endDate', date)}
                                selected={dateRangeFilter.endDate ? moment(dateRangeFilter.endDate, 'DD/MM/yyyy').toDate() : null} />
                        </div>
                        <AppSelect value={monthFilter ? { label: monthFilter, value: monthFilter } : null}
                            menuPortalTarget={document.body}
                            // @ts-ignore
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            onChange={(e) => setMonthFilter(e ? e.value : null)}
                            isClearable={true}
                            placeholder='Select Month'
                            className='w-150px'
                            options={MONTHS.map((month) => ({ label: month, value: month }))} />
                    </div>
                    {isVersionLocked && <Typography weight='bold' className='align-self-center'>Version is locked</Typography>}
                    <div className='d-flex gap-3'>
                        <AppButton size='sm'
                            onClick={() => handleDuplicateRows(gridApi ? gridApi.getSelectedRows() : [])}>Duplicate Rows</AppButton>
                        <AppButton size='sm'
                            onClick={() => handleDuplicateScenario()}>Duplicate Scenario</AppButton>
                        <AppButton size='sm'
                            disabled={isVersionLocked}
                            onClick={() => handleUpdateRows(gridApi ? gridApi.getSelectedRows() : [])}>Update Row</AppButton>
                        <AppButton size='sm'
                            disabled={isVersionLocked}
                            onClick={() => handleDeleteModal(handleSaveSpreadsheet, gridApi)}>Delete Row</AppButton>
                        <AppButton size='sm'
                            disabled={isLoadingAddRow || isVersionLocked}
                            onClick={handleAddRowGrid}>Add Row</AppButton>
                        <AppButton disabled={isSaving || !isSheetUpdated || isVersionLocked} isLoading={isSaving} size='sm'
                            onClick={handleSaveSpreadsheet}>
                            Save
                        </AppButton>
                    </div>
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        sheetDataOptions={dataOptionsMemo}
                        pageSize={pageSize}
                        sheetData={[...sheetData]}
                        handleGridReady={handleGridReady}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged}
                        onRowSelect={onSelectionChanged} />
                    <div className='d-flex align-items-center justify-content-between my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2' id='selectedRows' />
                        <div className='d-flex align-items-center'><p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                            <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                                <option value='dynamic'>Dynamic</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                                <option value='300'>300</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetGridAirportsSchedule;
