import { useCallback, useEffect, useMemo } from 'react';
import { CellValueChangedEvent } from 'ag-grid-community';
import useToast from 'app/hooks/useToast';
import useConfigStore from 'app/store/userConfig';
import useRightDrawerStore from 'app/store/rightDrawer';
import Typography from 'app/components/Typography';
import { ReportsType } from 'app/api/reports/types';
import { ORIENTATION } from 'app/utils/constants';
import { updateRightDrawerData } from 'app/utils/helpers';
import AgGrid from './AgGrid';
import useSpreadsheet from '../hooks/useSpreadsheet';
import { HLSHEmployees } from 'app/api/hlsh/types';
import { addHLSHEmployeeTeam, deleteHLSHEmployeeTeam, useGetHLSHEmployeesData } from 'app/api/hlsh/hlsh';
import useCreateHLSHEmployeesColumns from './hooks/useCreateHLSHGridColumns';

import './spreadsheet-grid-hlsh.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

type Props = {
    report: ReportsType;
    className?: string;
}

const SpreadsheetHLSH = ({ report, className }: Props) => {
    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { setNotification } = useToast();
    const { navbarOrientation } = useConfigStore();

    const { data: sheetDataResponse, isLoading: isSpreadsheetDataLoading } = useGetHLSHEmployeesData(
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3
        });

    const { pageSize, isSheetUpdated, setIsSheetUpdated, handleGridReady, onPageSizeChanged } = useSpreadsheet(isSpreadsheetDataLoading);

    const sheetData: any = useMemo(() => sheetDataResponse ? JSON.parse(JSON.stringify(sheetDataResponse.data.data)) : [], [sheetDataResponse]);

    const createdColumns = useCreateHLSHEmployeesColumns(sheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [sheetData, report]);

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: false,
            content: () => <></>
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, sheetData]);

    const handleSaveSpreadsheet = async (data: CellValueChangedEvent<HLSHEmployees>) => {
        try {
            if (data.newValue) {
                await addHLSHEmployeeTeam(data.data.EMPLOYEE_ID, data.colDef.field!);
            } else {
                await deleteHLSHEmployeeTeam(data.data.EMPLOYEE_ID, data.colDef.field!);
            }

            setNotification('Sheet saved', 'success');

            setIsSheetUpdated(false);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setNotification(error.response.data.message, 'error');
            } else {
                setNotification('Failed to update spreadsheet', 'error');
            }
        }
    };

    const onCellValueChanged = useCallback((data: CellValueChangedEvent) => handleSaveSpreadsheet(data), []);

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
            </div>
            <div className={`spreadsheet-grid-hlsh card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3' />
                    <div className='d-flex justify-content-end gap-3' />
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        pageSize={pageSize}
                        sheetData={[...sheetData]}
                        handleGridReady={handleGridReady}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged} />
                    <div className='d-flex align-items-center justify-content-end my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                        <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                            <option value='dynamic'>Dynamic</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='300'>300</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetHLSH;
