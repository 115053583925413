import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { ICSSClientBudget, ICSSClientBudgetUpdate } from './types';

const GET_ICSS_DATA = 'getICSSClientBudgetData';
const GET_ICSS_CLIENT_NAME_OPTIONS = 'getICSSClientNameOptions';

const base = 'icssclientbudget';

const createQueryString = (queryParams: { [key: string]: string }) => {
    const queryString = Object.keys(queryParams)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');

    return queryString;
};

export function useGetICSSClientBudgetData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<ICSSClientBudget[]>([`${GET_ICSS_DATA}`], `/${base}/spreadsheet`, { ...options, method: 'GET' });
}

export function useGetICSSClientNameOptions({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<Omit<ICSSClientBudget, 'YEARLY_BUDGETS'>[]>([`${GET_ICSS_CLIENT_NAME_OPTIONS}`], `/${base}/spreadsheet/options`, { ...options, method: 'GET' });
}

export async function updateICSSClientBudget(data: ICSSClientBudgetUpdate[]) {
    return await makeApiRequest<boolean>(`/${base}/spreadsheet/update`, 'POST', data);
}

export async function createICSSFinancialYear(newFinancialYear: {newFinancialYear: string}) {
    const queryString = createQueryString(newFinancialYear);

    return await makeApiRequest<boolean>(`/${base}/spreadsheet/createfinancialyear?${queryString}`, 'POST');
}
