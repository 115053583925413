import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { HLSHEmployees } from './types';

const GET_HLSH_EMPLOYEES_DATA = 'getHLSHEmployeesData';

const createQueryString = (queryParams: { [key: string]: string }) => {
    const queryString = Object.keys(queryParams)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');

    return queryString;
};

// versions
export function useGetHLSHEmployeesData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<HLSHEmployees[]>([`${GET_HLSH_EMPLOYEES_DATA}`], '/hlshemployees/spreadsheet', { ...options, method: 'GET' });
}

export async function addHLSHEmployeeTeam(employeeId: number, teamName: string) {
    const queryString = createQueryString({ employeeId: employeeId.toString(), teamName });

    return await makeApiRequest<boolean>(`/hlshemployees/spreadsheet/addteam?${queryString}`, 'POST');
}

export async function deleteHLSHEmployeeTeam(employeeId: number, teamName: string) {
    const queryString = createQueryString({ employeeId: employeeId.toString(), teamName });

    return await makeApiRequest<boolean>(`/hlshemployees/spreadsheet/deleteteam?${queryString}`, 'DELETE');
}
// export async function updateAircraftVersionData(data: AircraftVersionData[]) {
//     return await makeApiRequest<any>('/aircraftversion/spreadsheet/update', 'POST', data);
// }

// export async function deleteAircraftVersionData(ids: number[]) {
//     return await makeApiRequest<boolean>('/aircraftversion/spreadsheet/delete', 'DELETE', ids);
// }
